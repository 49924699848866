import type { ChildTrackingFeature, FeatureTrackingData, TrackingFeature } from "./FeatureTrackingData";
import { moduleLogger } from "./util/Logger";

const log = moduleLogger.scope("tracking");

export class TrackingService {
	sendEvent(payload: FeatureTrackingData) {
		log.info(`[benefit-context] Send 'event'`, payload);
		window.o_global.eventQBus.emit("tracking.bct.submitAction", {}, payload);
	}

	sendMergeForCinema(payload: (TrackingFeature | ChildTrackingFeature)[]) {
		log.info(`[benefit-context] Send 'merge for cinema'`, payload);
		window.o_global.eventQBus.emit("tracking.bct.addFeaturesToPageImpression", payload);
	}
}
